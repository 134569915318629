/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { UPDATE_ACTIVE_CATEGORY, UPDATE_CATEGORY } from './DocCategory.action';

export const initialState = {
    category: [],
    activeCategory: '',
    isCategoryLoaded: false
};

export const DocCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_CATEGORY:
        const {
            category,
            isCategoryLoaded
        } = action;

        const categoryParsed = JSON.parse(category);

        return {
            ...state,
            category: categoryParsed,
            isCategoryLoaded
        };
    case UPDATE_ACTIVE_CATEGORY:
        const { activeCategory } = action;

        return {
            ...state,
            activeCategory
        };
    default:
        return state;
    }
};

export default DocCategoryReducer;
