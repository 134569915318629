/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */

export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_LOADING = 'UPDATE_ITEM_LOADING';

export const updateItem = (item) => ({
    type: UPDATE_ITEM,
    item
});

export const updateItemLoading = (isLoading) => ({
    type: UPDATE_ITEM_LOADING,
    isItemLoaded: isLoading
});
