/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { UPDATE_ITEM, UPDATE_ITEM_LOADING } from './DocItem.action';

export const initialState = {
    item: null,
    isItemLoaded: true
};

export const DocItemReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_ITEM:
        const {
            item
        } = action;

        return {
            ...state,
            item
        };
    case UPDATE_ITEM_LOADING:
        const { isItemLoaded } = action;

        return {
            ...state,
            isItemLoaded
        };
    default:
        return state;
    }
};

export default DocItemReducer;
