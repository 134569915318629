/* eslint-disable */
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import ContactFormReducer from 'Store/ContactForm/ContactForm.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import ProductCompareReducer from 'Store/ProductCompare/ProductCompare.reducer';
import ProductListReducer from 'Store/ProductList/ProductList.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';

import CategoriesReducer from '@scandipwa/blog/src/store/Categories/Categories.reducer';
import PostsReducer from '@scandipwa/blog/src/store/Posts/Posts.reducer';
import PostsDetailsReducer from '@scandipwa/blog/src/store/PostsDetails/PostsDetails.reducer';

import DocCategoryReducer from 'Store/DocCategory/DocCategory.reducer';
import DocItemReducer from 'Store/DocItem/DocItem.reducer';
 
/** @namespace Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    CheckoutReducer,
    ProductListReducer,
    ProductListInfoReducer,
    NoMatchReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    ContactFormReducer,
    ProductCompareReducer,
    CategoriesReducer,
    PostsReducer,
    PostsDetailsReducer,
    DocCategoryReducer,
    DocItemReducer
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[name] = reducer;
            store.injectReducer(name, reducer);
        }
    );

    return store;
}

