/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_ACTIVE_CATEGORY = 'UPDATE_ACTIVE_CATEGORY';

export const updateCategory = (category) => ({
    type: UPDATE_CATEGORY,
    category,
    isCategoryLoaded: true
});

export const updateActiveCategory = (category) => ({
    type: UPDATE_ACTIVE_CATEGORY,
    activeCategory: category
});
